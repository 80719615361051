<template>
  <div class="register">
    <div class="login-logo">
      <router-link
          to="/"
          tag="img"
          :src="require(`@/assets/images/logo/logo-black.png`)"/>
    </div>
    <!-- register section-->
    <section class="register-section">
      <p>SIGN UP</p>
      {{user}}
      <button class="sns-sign-in">SIGN IN WITH TWITTER</button>
      <button class="sns-sign-in">SIGN IN WITH FACEBOOK</button>
      <div class="auth-input">
        <form @submit.prevent="register">
          <label for="email">Email</label>
          <input type="email" id="email" placeholder="" v-model="email" name="email" value>
          <label for="username">Username</label>
          <input type="text" id="username" placeholder="" v-model="name" name="name" value>
          <label for="password">Password</label>
          <div class="input-password">
            <input type="text" id="password" ref="password" placeholder="" v-model="password" name="password" value>
            <span @click="changeInputType"><v-icon>{{ eyeIcon }}</v-icon></span>
          </div>
          <div class="user-type-toggle">
            <span>Are you an artist or represent one?</span>
            <toggle-button v-model="switchValue"/>
          </div>
          <button type="submit" name="button">CREATE ACCOUNT</button>
        </form>
        <p>By clicking create account you agree to the <a @click="goPage('tos')">Terms of service</a></p>
        <p>Already have an account? <a @click="goPage('auth')">Log in</a></p>
      </div>
    </section>
  </div>
</template>

<script>
import CardMain from '../../components/Card/CardMain'
import {POSITION} from "vue-toastification";
import  user from "../../../db/user.json"

export default {
  name: 'Register',
  components: {
    CardMain
  },
  data() {
    return {
      eyeIcon: 'mdi-eye-off',
      switchValue: true,
      name: '',
      email: '',
      password: '',
      errorEmail: '',
      errorPwd: '',
      user: user,
    }
  },
  methods: {
    changeInputType() {
      if (this.$refs.password.type === 'text') {
        this.$refs.password.type = 'password'
        this.eyeIcon = 'mdi-eye-off'
      } else {
        this.$refs.password.type = 'text'
        this.eyeIcon = 'mdi-eye'
      }
    },
    goPage(name) {
      this.$router.push({name: name})
    },
    register() {
      this.$store.dispatch('app/register', {
        name: this.name,
        email: this.email,
        password: this.password
      })
          .then(() => {
            // this.$router.push({name: 'dashboard'});
          })
          .catch(err => {
            for (let error of err.response.data.errors) {
              switch (error) {
                case 'An account with this email already exists.':
                  this.errorEmail = error;
                  break;
                case 'Password too short.':
                  this.errorPwd = error;
                  break;
              }

            }
            this.makeToast()
          });
    },
    makeToast() {
      if(this.errorEmail) {
        this.$toast(this.errorEmail, {
          position: POSITION.BOTTOM_CENTER,
          toastClassName: "error-toast-class",
          bodyClassName: "error-class",
        })
      }
      if(this.errorPwd) {
        this.$toast(this.errorPwd, {
          position: POSITION.BOTTOM_CENTER,
          timeout: 2000,
          toastClassName: "error-toast-class",
          bodyClassName: "error-class",
        })
      }
    },
  }
}
</script>