<template>
    <div
        class="drops-card"
        @click="goClick"
    >
      <img
          :src=CoverImg
          alt="drops-main-img" />
      <div class="sold-out">
        {{ StatusLabel }}
      </div>
      <div class="drops-explain">
        <div>
          <v-avatar>
            <img
                :src=AvatarAsset
                alt="John"
            >
          </v-avatar>
          <div class="drop-info">
            <p>{{ Title }}</p>
            <p>{{ Contributors }}</p>
          </div>
        </div>
        <div>
          {{ Type }}
        </div>
      </div>
    </div>
</template>
<script>
import {reactive, toRefs} from '@vue/composition-api'

export default {
  components: {},
  props: {
    title: {type: String, default: ''},
    statusLabel: {type:String, default: ''},
    contributors:  {type:String, default: ''},
    avatarAsset:  {type:String, default: ''},
    coverImg: {type: String, default: ''},
    type: {type: String, default: ''},
  },
  setup(props) {
    const state = reactive({

      Title: props.title,
      StatusLabel: props.statusLabel,
      Contributors: props.contributors,
      AvatarAsset: props.avatarAsset,
      CoverImg: props.coverImg,
      Type: props.type,
    })
    return toRefs(state)
  },
  methods: {
    goClick() {
      this.$emit('click')
    },
  },
}
</script>
